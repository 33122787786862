import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/usr/src/app/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "planday-structure"
    }}>{`Planday Structure`}</h1>
    <p>{`In Planday, you access a Portal where your business is structured into Departments, Employee groups, Sections, Positions, Shifts, and Shift types. `}</p>
    <p><img alt="Structure of Planday" src={require("./images/Structure_of_Planday.png")} /></p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Department: `}</strong>{`A place of work. This is generally the physical location of your business. Departments are optional, but if a portal uses departments every shift is connected to one department.`}</li>
      <li parentName="ul"><strong parentName="li">{`Employee group: `}</strong>{`What an employee works as (e.g. a function or role). Employee group is required and every shift is connected to one employee group.`}</li>
      <li parentName="ul"><strong parentName="li">{`Section:`}</strong>{` A label for a selection of Positions. Sections allow you to easily view all Positions under a particular Section label.`}</li>
      <li parentName="ul"><strong parentName="li">{`Position: `}</strong>{`A label that explains more about what an employee will be responsible for during their shift (e.g. Waiter tables 1-5).`}</li>
      <li parentName="ul"><strong parentName="li">{`Shift: `}</strong>{`When an employee is scheduled to work. `}</li>
      <li parentName="ul"><strong parentName="li">{`Shift type: `}</strong>{`A label for abnormal shifts (e.g. sick, training, off-site meeting).`}</li>
      <li parentName="ul"><strong parentName="li">{`Networks and master portals`}</strong>{` Though the highest entity in Planday is a Portal, some of our larger customers have their portals structured in a network. A network is a link between a master portal and multiple sub portals. Administrators on the master portal will have easy access to specific areas of the sub portals, which makes it easier to manage operations from a central hub.`}</li>
    </ul>
    <p><em parentName="p">{`Note: Please be aware that employeeIds are only unique within portals. You can create a combination of portalId and employeeId to ensure a unique identifier for the users across portals.`}</em></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      